import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, MenuItem, Paper, Select, Stack, TextField, Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import { ChannelIcon, formatCurrency, formatDate, formatNumber } from '../../../helpers/UtilityHelper';
import { useGetChannelOrderQuery } from '../../../redux/commonRTK';
import _ from 'lodash'
import Loader from '../../../common/Loader';
import MUIModal from '../../../common/MUIModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getMerchant } from '../../../helpers/ReduxHelpers';
import { getGeneric, apiConstants, postGeneric, post } from '../../../common/constants';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFRadioGroup, RHFSelectField, RHFSearchableSelectField, RHFTextField } from '../../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { useGetCouriersQuery } from '../../../redux/commonRTKPersist';
import { useSnackbar } from 'notistack';
import { useGridApiContext } from '@mui/x-data-grid';
import EditCustomerDetails from './EditCustomerDetails';
import { addDays, format } from 'date-fns';
import { CancelSharp, ExpandMoreOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { getServiceability } from '../../../redux/actions/serviceability/serviceabilityAction';

const PlacedOrderForContext = React.createContext()
const MerchantContext = React.createContext()
const diableSelection = ['CANCELLED']

export default function UnfulfilledChannelOrders() {
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(30);
  const [orderItems, setOrderItems] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [placeOrderFor, setPlaceOrderFor] = useState([])
  const [merchant, setMerchant] = useState(null)
  // const [skuDetails, setSkuDetails] = useState([])
  // const [finalData, setFinalData] = useState([])
  const [fulfilledItem, setFulfilledItem] = useState(null)
  const [andFilters, setAndFilters] = useState([]);
  const merchantD = getMerchant();
  const merchantId = merchantD.id ? merchantD.id : ''


  const defaultFilters = [
    { field: "channelOrderStatus", operator: "in", value: ['CREATED', 'CANCELLED', 'HOLD', 'DELETED', 'RELEASE', 'PARTIALLY_FULLFILLED'] },
    { field: "merchant.$id", operator: "eq", value: merchantId }
  ]

  const methods = useForm({
    defaultValues: {
      order_number: '',
      awb_Number: '',
      payment_mode: '',
      email: '',
      phone: ''
    }
  });

  const {
    handleSubmit,
    reset
  } = methods;

  const { data, isLoading, isFetching, refetch } = useGetChannelOrderQuery({
    andfilter: [...defaultFilters, ...andFilters],
    orfilter: [],
    "offset": (page * sizePerPage),
    "limit": sizePerPage,
    "excludes": "sla,merchant,mappedOrderRule"
  })



  useEffect(() => {
    if (merchantId) {
      getGeneric(`${apiConstants.GET_MERCHANT}/${merchantId}`)
        .then((response) => {
          setMerchant(response?.data)
        }).catch((err) => console.error(err))
    }
  }, [merchantId])

  // useEffect(() => {
  //   if (data?.data?.length > 0) {
  //     let skus = data?.data?.map((row) => row.orderItems)
  //     skus = skus.flat().map((row) => row.sku_id)
  //     skus = [...new Set(skus)]
  //     postGeneric(apiConstants.GET_SKU_DIM, { skuList: skus })
  //       .then((_response) => {
  //         setSkuDetails(_response.data)
  //       }).catch((_err) => {
  //         console.log(_err)
  //       })
  //   }
  // }, [data])

  const applyPickupFilters = (filters) => {
    let appliedPickupFilters = [];
    if (filters.order_number) {
      appliedPickupFilters.push({ "field": "order_number", "operator": "eq", "value": filters.order_number })
    }
    if (filters.payment_mode) {
      appliedPickupFilters.push({ "field": "paymentMode", "operator": "eq", "value": filters.payment_mode })
    }
    if (filters.phone) {
      appliedPickupFilters.push({ "field": "customer.phone", "operator": "eq", "value": '+91' + filters.phone })
    }
    if (filters.email) {
      appliedPickupFilters.push({ "field": "customer.email", "operator": "eq", "value": filters.email })
    }
    if (filters.store_name) {
      appliedPickupFilters.push({ "field": "store_name", "operator": "eq", "value": filters.store_name })
    }
    setAndFilters(appliedPickupFilters)
    handleAndFiltersChange(appliedPickupFilters);
  }

  const handleAndFiltersChange = (newAndFilters) => {
    refetch({
      andfilter: [...defaultFilters, ...newAndFilters]
    });
  }

  // useEffect(() => {
  //   if (data?.data?.length > 0) {
  //     const fData = data?.data?.map((row) => {
  //       const rowItems = row?.orderItems?.map((oi) => {
  //         const skuDetail = skuDetails?.find((sku) => sku.skuCode === oi.sku_id)
  //         return { ...oi, ...skuDetail }
  //       })

  //       return { ...row, orderItems: rowItems }
  //     })
  //     setFinalData(fData)
  //   } else if (data?.data?.length === 0) {
  //     setFinalData([])
  //   }

  // }, [skuDetails, data])

  const skuData = (fullfillData) => {
    setFulfilledItem(fullfillData?.fullfilledItems)
  }


  const columns = [
    {
      field: 'channel',
      sortable: false,
      flex: 0.9,
      headerAlign: "center",
      headerName: 'Order No',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span>{row.order_number}</span>
            <span><ChannelIcon name={row.channel} /></span>
          </div>
        );
      },
    },
    {
      field: 'store_name', flex: 0.9, sortable: false, headerAlign: "left", headerName: 'Store Name'
    },
    {
      field: 'order_verification',
      sortable: false,
      flex: 1.1,
      headerAlign: "left",
      headerName: 'WhatsApp Verification',
      renderCell: (params) => {
        const { row } = params;

        if (row.order_verification === "true") {
          return (
            <CheckCircleIcon color='success' />
          );
        } else if (row.order_verification === "false") {
          return (
            <CancelSharp color='error' />
          );
        } else {
          return (
            <span>NA</span>
          );
        }
      },
    },
    {
      field: 'channelOrderId', flex: 1, sortable: false, headerAlign: "left", headerName: 'Order Date', renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="left" variant="body2" fontWeight={500}>
              {formatDate(row.createdDate, "dd MMM, yyyy")}
            </Typography>
            <Typography textAlign="left" variant="body2">
              {formatDate(row.createdDate, "hh:mm aaa")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'customer', flex: 1.5, sortable: false, headerAlign: "left", headerName: 'Customer Details', renderCell: ({ row }) => {
        return (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography textAlign="left" variant="body2" fontWeight={500}>
                  {row.customer?.first_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign="left" variant="body2">
                  {row.customer?.email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign="left" variant="body2">
                  {row.customer?.phone}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'items', flex: 0.8, sortable: false, headerAlign: "left", headerName: 'Items', renderCell: (params) => {
        const { row } = params;
        return <Button onClick={() => setOrderItems(row.orderItems)} sx={{
          letterSpacing: 0,
          fontSize: 12
        }} size="small">View Items</Button>
      },
    },
    {
      field: 'paymentDetails', headerAlign: "center", sortable: false, align: "center", headerName: 'Payment Details', flex: 1, renderCell: (params) => {
        const { row } = params;
        const paymentMode = row.payment_mode ? row.payment_mode : '';
        const color = paymentMode === 'COD' ? "error" : "success";
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {row.total_price ? (
              <Typography textAlign="center" variant="body2">
                {formatCurrency(row.total_price)}
              </Typography>
            ) : ""}
            <Chip size="small" sx={{ fontWeight: 500, mt: 1 }} color={color} label={paymentMode} />
          </Box>
        )
      },
    },
    {
      field: 'channelOrderStatus', headerAlign: "center", sortable: false, align: "center", headerName: 'Status', flex: 1, renderCell: ({ row }) => {
        return (
          <Box>
            <Typography sx={{ mt: 1 }} textAlign="center" variant="body2">{_.startCase(row.channelOrderStatus)}</Typography>
          </Box>
        )
      }
    },
    {
      field: 'fulfilleditems', flex: 0.8, sortable: false, headerAlign: "left", headerName: 'Fulfilled Items', renderCell: (params) => {
        const { row } = params;
        return row.channelOrderStatus === "PARTIALLY_FULLFILLED" && row.fullfilledItems?.length > 0 ? <Button onClick={() => skuData(row)} sx={{
          letterSpacing: 0,
          fontSize: 12
        }} size="small">View Items</Button> : "-"
      },
    },
    {
      field: 'mesurements', headerAlign: "center", align: "center", sortable: false, headerName: 'Weight', flex: 0.9, renderCell: ({ row }) => {

        return (
          <Box>
            <Typography textAlign="center" variant="body2" fontWeight={500}>{(row.total_weight?.toFixed(2))} gm</Typography>
          </Box>
        )
      }
    },
    // {
    //   field: 'tags', flex: 1.2, sortable: false, headerAlign: "left", headerName: 'Tags',
    //   renderCell: ({ row }) => {
    //     if (row.tags) {
    //       const tags = row.tags.split(',')
    //       return (
    //         <React.Fragment>
    //           <div style={{ display: 'flex',flexWrap:'wrap', }}>
    //             {tags.map((t) => <Chip size="small" sx={{ m: '2px', fontSize: 10, fontWeight: 700 }} color='default' label={t.trim().toUpperCase()} />)}
    //           </div>
    //         </React.Fragment>
    //       )
    //     }
    //   }
    // },
    {
      field: 'tags', flex: 1.2, sortable: false, headerAlign: "left", headerName: 'Tags',
      renderCell: ({ row }) => {
        if (row.tags) {
          const tags = row.tags.split(',')
          return (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {tags.map((t) => <Chip size="small" sx={{ m: '2px', fontSize: 10, fontWeight: 700 }} color='default' label={t.trim().toUpperCase()} />)}
                </Grid>
              </Grid>
              {/* <div>
              </div> */}
            </React.Fragment>
          )
        }
      }
    },
    {
      field: 'action', headerAlign: "center", align: "center", sortable: false, headerName: 'Action', flex: 1.8, renderCell: ({ row }) => {
        if (diableSelection.includes(row.channelOrderStatus)) return;
        return (
          <>
            <Grid container spacing={1}>
              {/* <Grid item xs={12}></Grid> */}
              <Grid item xs={12}>
                <Button
                  sx={{ letterSpacing: 0 }}
                  disabled={(selectedRows.length > 0)}
                  variant="contained"
                  onClick={() => setPlaceOrderFor([row])}
                  size="small"
                // startIcon={<CheckCircleIcon fontSize="small" />}
                >
                  Create Shipment
                </Button>
                <Grid item xs={12}>

                  <Button
                    disabled={(selectedRows.length > 0)}
                    variant="contained"
                    onClick={() => { editCustomer(row) }}
                    size="small"
                    sx={{ mt: 0.5 }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}></Grid> */}

            </Grid >
          </>
        )
      }
    },
  ];

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const editCustomer = (row) => {
    setSelectedRow(row);
    setIsEditModalOpen(true);
  }
  const handleClose = () => {
    setIsEditModalOpen(false)
  }


  const bulkCreateHandler = () => {
    const channelOrders = data?.data?.filter((row) => selectedRows.includes(row.id))
    setPlaceOrderFor(channelOrders)
  }

  return (
    <div>
      {(isLoading || isFetching) && <Loader />}
      <MUIModal
        open={isEditModalOpen}
        title="Edit Details"
        handleClose={() => setIsEditModalOpen(false)} children={<EditCustomerDetails handleClose={handleClose} shippingAddress={selectedRow} refetch={refetch} />}>
      </MUIModal>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(applyPickupFilters)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                  <RHFTextField name="order_number" label="Order Number" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFSelectField
                    label="Payment Mode"
                    name="payment_mode"
                    options={[{ label: "COD", value: 'COD' }, { label: "PREPAID", value: 'PREPAID' }]}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="email" label="Customer Email" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="phone" label="Customer Phone" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="store_name" label="Store Name" color="secondary" variant="filled" />
                </Grid>
                {/* <Grid item xs={3} >
                  <RHFSelectField
                    label="Payment Mode"
                    name="payment_mode"
                    options={[{ label: "COD", value: 'COD' }, { label: "PREPAID", value: 'PREPAID' }]}
                    width="100%"
                  />
                </Grid> */}

                <Grid item xs={2}>
                  <Button type="submit" variant="contained">Filter</Button>
                  <Button sx={{ ml: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                    reset()
                  }}>Reset</Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction='row'
            sx={{
              mb: 1,
              justifyContent: 'flex-end'
            }}>
            <Button
              disabled={selectedRows.length === 0}
              onClick={bulkCreateHandler}
              variant='contained'
              startIcon={<CheckCircleIcon />}
            >
              Create Shipment
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ProzoDataGrid
            columns={columns}
            rows={data?.data || []}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            checkboxSelection
            isRowSelectable={({ row }) => !diableSelection.includes(row.channelOrderStatus)}
            onSelectionModelChange={(_ids) => setSelectedRows(_ids)}
            pagination={true}
            hideFooterPagination={false}
            filterMode={"server"}
            rowCount={data?.count}
            getRowId={(row) => row?.id}
          />
        </Grid>
      </Grid>
      <PlacedOrderForContext.Provider value={{ placeOrderFor, setPlaceOrderFor }}>
        <MerchantContext.Provider value={{ merchant, setMerchant }}>
          <PlaceOrderModal refetch={refetch} data={data} merchantId={merchantId} />
        </MerchantContext.Provider>
      </PlacedOrderForContext.Provider>

      <MUIModal
        open={orderItems ? true : false}
        title="Order Items"
        handleClose={() => setOrderItems(null)}
      >
        <ProzoDataGrid
          columns={[
            { field: 'sku_id', headerName: 'SKU', sortable: false, flex: 1 },
            { field: 'title', headerName: 'Name', sortable: false, flex: 1.5 },
            { field: 'selling_price', headerName: 'Price', sortable: false, flex: 1 },
            { field: 'units', headerName: 'Quantity', sortable: false, flex: 1 },
            {
              field: 'tax', headerName: 'Tax(%)', sortable: false, flex: 1, renderCell: ({ row }) => {
                return _.sumBy(row.taxlines, function (tax) {
                  return tax.rate
                })
              }
            },
            {
              field: 'dimensions', headerName: 'Dimensions/Weight', sortable: false, flex: 1.5, renderCell: ({ row }) => {
                let w = row.weight ? formatNumber(row.weight / 1000) + ' kg' : ''
                return (
                  <Box>
                    <Typography textAlign="center" variant="body2">{[row.item_length, row.item_breadth, row.item_height].join(' x ')}</Typography>
                    <Typography variant="body2" textAlign="center" fontWeight={500}>{w}</Typography>
                  </Box>
                )
              }
            },
          ]}
          rows={orderItems || []}
          boxShadow={0}
          hideFooterPagination
          rowCount={orderItems?.length || 0}
          getRowId={(row) => row.id}
        />
      </MUIModal>
      <MUIModal
        open={fulfilledItem ? true : false}
        title="Order Items"
        handleClose={() => setFulfilledItem(null)}
      >
        <ProzoDataGrid
          columns={[
            { field: 'sku_id', headerName: 'SKU', sortable: false, flex: 0.8, },
            {
              field: 'awbNumber', headerName: 'AWB Number', sortable: false, flex: 0.8, renderCell: ({ row }) => {
                return row?.lastfulfilledUnits?.awbNumber
              }
            },
            { field: 'title', headerName: 'Name', sortable: false, flex: 0.8 },
            { field: 'selling_price', headerName: 'Price', sortable: false, flex: 0.8 },
            { field: 'units', headerName: 'Total Quantity', sortable: false, flex: 0.8 },
            {
              field: 'skuCount', headerName: 'Fullfilled Quantity', sortable: false, flex: 0.8, renderCell: ({ row }) => {
                return row?.lastfulfilledUnits?.skuCount
              }
            },
            {
              field: 'tax', headerName: 'Tax(%)', sortable: false, flex: 0.8, renderCell: ({ row }) => {
                return _.sumBy(row.taxlines, function (tax) {
                  return tax.rate
                })
              }
            },
            {
              field: 'dimensions', headerName: 'Dimensions/Weight', sortable: false, width: 150, renderCell: ({ row }) => {
                let w = row.weight ? formatNumber(row.weight / 1000) + ' kg' : ''
                return (
                  <Box>
                    <Typography textAlign="center" variant="body2">{[row.item_length, row.item_breadth, row.item_height].join(' x ')}</Typography>
                    <Typography variant="body2" textAlign="center" fontWeight={500}>{w}</Typography>
                  </Box>
                )
              }
            },
          ]}
          rows={fulfilledItem?.length > 0 ? fulfilledItem.map((val, index) => ({ ...val, id: index })) : []}
          boxShadow={0}
          hideFooterPagination
          rowCount={fulfilledItem?.length || 0}
          getRowId={(row) => row.id}
        />
      </MUIModal>
    </div>
  );
};



function PlaceOrderModal({ refetch, data, merchantId }) {
  const { placeOrderFor, setPlaceOrderFor } = React.useContext(PlacedOrderForContext)
  const { merchant } = React.useContext(MerchantContext)
  const [selectedRows, setSelectedRows] = React.useState([])
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const { blendedData } = useSelector(state => state?.report);

  const { data: allCouriers } = useGetCouriersQuery()
  const [isCreating, setIsCreating] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [newCourier, setNewCourier] = useState([])

  const pickupLocations = (merchant?.pickup_details || []).filter(pl => pl.status === "ACTIVE")
  const defaultPickupLocation = pickupLocations.find(pl => pl.is_default)

  const method = useForm({
    resolver: yupResolver(Yup.object().shape({
      pickupLocation: Yup.string().required("Please select pickup location!"),
      courierPartner: Yup.string(),
    })),
    defaultValues: {
      pickupLocation: "",
      courierPartner: "",
      courierType: 'auto'
    }
  })

  useEffect(() => {
    if (placeOrderFor.length === 1) {
      if (placeOrderFor[0].channel === "WOO_COMMERCE") {
        setSelectedRows(placeOrderFor[0]?.orderItems?.map(oi => oi?.id))
      }
    }
  }, [placeOrderFor])

  const { handleSubmit, watch, setValue, reset: resetForm } = method

  React.useEffect(() => {
    setValue("pickupLocation", defaultPickupLocation?.id || "")
  }, [setValue, defaultPickupLocation])

  const placeOrder = async (data) => {
    setIsCreating(true)
    const channelOrders = [...placeOrderFor]

    try {
      for (const channelOrder of channelOrders) {
        const pickup_details = pickupLocations.find((p) => p.id === data.pickupLocation)
        let fullfilableItems = [];

        if (placeOrderFor.length === 1) {
          fullfilableItems = channelOrder?.orderItems.filter((item) => selectedRows.includes(item.id))
        } else {
          fullfilableItems = [...(channelOrder?.orderItems || [])]
        }

        fullfilableItems = fullfilableItems?.map((item) => {
          let k = { ...item }
          // if (!k.sku_id) {
          //   let v = k.variant_id ? k.variant_id : '';
          //   k.sku_id = "SKU " + v;
          //   return k;
          // }

          return k;

        })

        fullfilableItems = fullfilableItems.map((item) => ({
          ...item,
          fulfillable_quantity: selectedQuantities[item.id] !== undefined ? selectedQuantities[item.id] : item.fulfillable_quantity
        }));

        const request = {
          pickup_details: {
            from_city: pickup_details?.city,
            from_country: pickup_details?.country,
            from_name: pickup_details?.name,
            from_state: pickup_details?.state,
            from_phone_number: pickup_details?.contactNumber,
            from_email: pickup_details?.email,
            gstin: pickup_details?.gstin,
            from_addresstype: 'PICKUP',
            from_address: pickup_details?.address_line,
            from_pincode: pickup_details?.pincode,
          },
          courier_id: data.courierType === "auto" ? null : data.courierPartner,
          channelOrderId: channelOrder.channelOrderId,
          order_type: 'Forward Shipment',
          channel: channelOrder.channel,
          fullfilableItems
        }
        //if (fullfilableItems.length === 1) request.fullfilableItems = fullfilableItems
        // return false;
        await postGeneric(apiConstants.CREATE_SELECTOR, request)
      }
      enqueueSnackbar("Order Successfully Placed", { variant: 'success' });
      setPlaceOrderFor([])
      refetch()
      setSelectedQuantities({});
    } catch (_err) {
      console.log(_err)
      enqueueSnackbar(_err?.response?.data?.exception?.message || "Order Couldn't be Placed", { variant: 'error' });
    }
    setIsCreating(false)
  }
  const courierSelection = watch('courierType')
  const pickupLocation = watch('pickupLocation')

  useEffect(() => {
    const pickup_details = pickupLocations.find((p) => p.id === pickupLocation);
    let orderList = [];

    for (let i = 0; i < selectedRows?.length; i++) {
      orderList = placeOrderFor[0]?.orderItems.filter((item) => item.id === selectedRows[i])
    }

    let length = _.sumBy(orderList, "item_length")
    let breadth = _.sumBy(orderList, "item_breadth")
    let height = _.sumBy(orderList, "item_height")
    let weight = _.sumBy(orderList, "weight")
    if (pickup_details) {
      post(`${apiConstants.BASE_LOCAL}${apiConstants.SHIPMENT_RATE_CALCULATOR}`, {
        orderType: "FORWARD",
        to: parseInt(placeOrderFor[0]?.shipping_address?.zip),
        from: parseInt(pickup_details?.pincode),
        paymentType: placeOrderFor[0]?.payment_mode.toLowerCase() === "prepaid" ? "PREPAID" : "COD",
        "length": length,
        "breadth": breadth,
        "height": height,
        "weight": weight ? weight : 0.1,
        "dispatchMode": "SURFACE",
        "codAmount": placeOrderFor[0]?.cod || 0,
        "isMerchant": true,
        onlyCouriersAsPerRuleMapping: true,
        merchantOid: merchantId

      }).then((res) => {
        const pricing = res?.data;
        const newCourier = allCouriers?.map((d) => {
          const updatedCourier = { ...d };
          pricing.forEach((t) => {
            if (t.provider.id === updatedCourier.prozoId) {
              updatedCourier.priceAfterTax = t.priceAfterTax;
              updatedCourier.providerMinTatDays = t.providerMinTatDays;
              updatedCourier.providerMaxTatDays = t.providerMaxTatDays;
            }
          });
          return updatedCourier;
        })
        let filteredCouriers = newCourier.filter(item =>
          item.priceAfterTax &&
          item.type === 'FORWARD'
        )
        const now = new Date();
        filteredCouriers.forEach(shipment => {
          let minTatDays = shipment.providerMinTatDays || 0;
          let maxTatDays = shipment.providerMaxTatDays || 0;
          shipment.minDeliveryDate = format(addDays(now, minTatDays), 'dd, MMM');
          shipment.maxDeliveryDate = format(addDays(now, maxTatDays), 'dd, MMM');
        });
        setNewCourier(filteredCouriers);
        // setNewCourier(newCourier)
      }).catch((err) => console.log(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupLocation, selectedRows, JSON.stringify(placeOrderFor)])


  const RenderEditCell = ({ row }) => {
    const apiRef = useGridApiContext()
    const updaeDimensions = (e, row) => {
      e.preventDefault()
      const item_length = e.target.l.value
      const item_breadth = e.target.b.value
      const item_height = e.target.h.value
      const weight = e.target.w.value
      const orderDetails = placeOrderFor[0]
      const orderItems = orderDetails?.orderItems?.map((oi) => {
        if (oi.id !== row.id) return oi
        return { ...oi, item_length, item_breadth, item_height, weight }
      })

      setPlaceOrderFor([{ ...orderDetails, orderItems }])
      apiRef.current.stopCellEditMode({ id: row.id, field: 'dimensions' })
    }



    return (
      <form onSubmit={(e) => updaeDimensions(e, row)}>
        <Box sx={{ p: 1, display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0px 3px' }}>
          <TextField required label="L" name="l" size='small' variant="filled" defaultValue={row.item_length} />
          <TextField required label="B" name="b" size='small' variant="filled" defaultValue={row.item_breadth} />
          <TextField required label="H" name="h" size='small' variant="filled" defaultValue={row.item_height} />
          <TextField required label="W" name="w" size='small' variant="filled" defaultValue={row.weight} />
          <Button type="submit" sx={{ mt: 1 }} variant="contained" size="small">Save</Button>
        </Box>
      </form>
    )
  }


  const handleQuantityChange = (rowId, newValue) => {
    setSelectedQuantities(prevState => ({
      ...prevState,
      [rowId]: newValue
    }));
  };

  const fullFillableTableColumns = [
    {
      field: 'sku_id',
      headerName: 'SKU',
      sortable: false,
      flex: 1,
      editable: true,
    },
    {
      field: 'title',
      headerName: 'Name',
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => {
        let title = row.title;
        let variant = row.variant_title ? '( Variant: ' + row.variant_title + ' )' : '';
        return (
          <div>
            <div>{title}</div>
            <div style={{ whiteSpace: 'pre-line' }}>{variant}</div>
          </div>
        );
      },
    },
    { field: 'selling_price', headerName: 'Price', sortable: false, flex: 0.8, editable: true },
    {
      field: 'tax',
      headerName: 'Tax(%)',
      sortable: false,
      flex: 0.8,
      renderCell: ({ row }) => {
        return _.sumBy(row.taxlines, function (tax) {
          return tax.rate;
        });
      },
    },
    {
      field: 'dimensions',
      editable: true,
      headerName: 'Dimensions/Weight',
      sortable: false,
      flex: 1.2,
      renderEditCell: ({ row }) => <RenderEditCell row={row} />,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="center" variant="body2">
              {[row.item_length, row.item_breadth, row.item_height].join(' x ')}
              <strong style={{ marginLeft: 10 }}>({formatNumber(row.weight / 1000)}Kg)</strong>
            </Typography>
            <Typography textAlign="center" variant="caption" sx={{ fontSize: '8px' }}>
              Double click to edit
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'fulfillable_quantity',
      headerName: 'Quantity',
      sortable: false,
      flex: 0.8,
      renderCell: ({ row }) => {
        const options = generateQuantityOptions(row.fulfillable_quantity);
        const selectedQuantity = selectedQuantities[row.id] !== undefined ? selectedQuantities[row.id] : row.fulfillable_quantity;
        return (
          <Select
            value={selectedQuantity}
            onChange={(event) => { handleQuantityChange(row.id, event.target.value) }}
            sx={{ width: 100, height: 40 }}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      }
    },
  ];

  const fullFilledTableColumns = [
    {
      field: 'sku_id',
      headerName: 'SKU',
      sortable: false,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Name',
      sortable: false,
      flex: 1.2,
      renderCell: ({ row }) => {
        let title = row.title;
        let variant = row.variant_title ? '( Variant: ' + row.variant_title + ' )' : '';
        return (
          <div>
            <div>{title}</div>
            <div style={{ whiteSpace: 'pre-line' }}>{variant}</div>
          </div>
        );
      },
    },
    { field: 'selling_price', headerName: 'Price', sortable: false, flex: 0.8 },
    {
      field: 'fulfilledQuantity',
      headerName: 'Fullfilled Quantity',
      sortable: false,
      flex: 0.8,
      renderCell: ({ row }) => {
        const lastFulfilledQuantity = row?.lastfulfilledUnits
        return lastFulfilledQuantity ? lastFulfilledQuantity?.skuCount : 0;
      }
    },
    {
      field: 'tax',
      headerName: 'Tax(%)',
      sortable: false,
      flex: 0.8,
      renderCell: ({ row }) => {
        return _.sumBy(row.taxlines, function (tax) {
          return tax.rate;
        });
      },
    },
    {
      field: 'dimensions',
      editable: true,
      headerName: 'Dimensions/Weight',
      sortable: false,
      flex: 1.2,
      renderEditCell: ({ row }) => <RenderEditCell row={row} />,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="center" variant="body2">
              {[row.item_length, row.item_breadth, row.item_height].join(' x ')}
              <strong style={{ marginLeft: 10 }}>({formatNumber(row.weight / 1000)}Kg)</strong>
            </Typography>
            {row.weight && (
              <Typography textAlign="center" variant="caption" sx={{ fontSize: '8px' }}>
                Double click to edit
              </Typography>
            )}
            {!row.weight && (
              <Typography textAlign="center" variant="caption" sx={{ fontSize: '8px', color: 'red' }}>
                Please update weight before creating shipment
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: 'awb',
      headerName: 'AWB Number',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {row?.lastfulfilledUnits ?
              <Link target='_blank' style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row?.lastfulfilledUnits?.awbNumber}`}>
                {row?.lastfulfilledUnits?.awbNumber}
              </Link> : "-"}
          </>
        )
      },
    },
  ];


  const generateQuantityOptions = (currentQuantity) => {
    const options = [];
    for (let i = currentQuantity; i >= 1; i--) {
      options.push({ label: `${i}`, value: i });
    }
    return options;
  };

  const filteredRows = placeOrderFor?.[0]?.orderItems?.filter(
    (item) => !item?.fullfilled && item.fulfillable_quantity > 0
  );
  return (
    <React.Fragment>
      <MUIModal
        maxWidth="lg"
        open={placeOrderFor.length > 0 ? true : false}
        title="Create Shipment"
        handleClose={() => {
          setPlaceOrderFor([])
          resetForm()
        }}
      >
        <Paper sx={{ mb: 4, mt: 1, maxHeight: 300, overflow: 'auto' }} variant="outlined">
          <Accordion >
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: '#3783e71a' }}
            >
              {/* <Box sx={{ backgroundColor: '#3783e71a', borderRadius: 1, p: 1, border: '1px solid #ccc' }}> */}
              <Typography variant="subtitle1">
                Fullfilled Items
              </Typography>
              {/* </Box> */}
            </AccordionSummary>
            <AccordionDetails>
              {placeOrderFor && placeOrderFor?.length !== 1 ? "No data" : (
                <ProzoDataGrid
                  columns={fullFilledTableColumns}
                  rows={placeOrderFor[0]?.fullfilledItems ? placeOrderFor[0]?.fullfilledItems.map((val, index) => ({ ...val, id: index })) : []}
                  boxShadow={0}
                  paginationMode="client"
                  hideFooterPagination
                  rowCount={(placeOrderFor[0]?.fullfilledItems && placeOrderFor[0]?.fullfilledItems?.length) || 0}
                  getRowId={(row) => row?.id}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>
        {placeOrderFor?.length === 1 && (
          <Box sx={{ mb: 1 }}>
            <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>Items Available for Fulfillment</Typography>
            <ProzoDataGrid
              columns={fullFillableTableColumns}
              rows={filteredRows}
              experimentalFeatures={{ newEditingApi: true }}
              boxShadow={0}
              checkboxSelection={placeOrderFor && placeOrderFor[0] && placeOrderFor[0]?.channel !== 'WOO_COMMERCE'}
              paginationMode="client"
              onSelectionModelChange={(_ids) => setSelectedRows(_ids)}
              hideFooterPagination
              rowCount={filteredRows?.length}
            //getRowId={(row) => row?.id || row.sku}
            />
          </Box>
        )}
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" fontWeight={700} sx={{ mb: 1 }}>Delivery Address</Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>{placeOrderFor[0]?.shipping_address?.address1},{placeOrderFor[0]?.shipping_address?.address2},{placeOrderFor[0]?.shipping_address?.city},{placeOrderFor[0]?.shipping_address?.state || placeOrderFor[0]?.shipping_address?.province},{placeOrderFor[0]?.shipping_address?.zip}</Typography>
            </Box>
          </Grid>
        </Grid>
        <br />
        <FormProvider methods={method} onSubmit={handleSubmit(placeOrder)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFSearchableSelectField
                variant="filled"
                width="100%"
                name="pickupLocation"
                label="Pickup Location *"
                options={pickupLocations.map((pickup) => ({
                  label: [pickup.name, pickup.address_line, pickup.city, pickup.state, pickup.pincode].join(", "),
                  value: pickup.id
                }))}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Link to="/merchant-step2" style={{ color: '#3498db', textDecoration: 'underline', cursor: 'pointer' }} state={{ addPopUp: true }}>
                Add New Pickup Location
              </Link>
            </Grid>
            {/* {console.log(merchant,placeOrderFor)} */}
            {blendedData ?
              <Grid item xs={12}>
                <RHFRadioGroup label="Courier Selection" name="courierType" options={[
                  { label: 'Auto', value: 'auto' },
                ]} disabled={pickupLocation?.length === 0} />
              </Grid>
              :
              <Grid item xs={12}>
                <RHFRadioGroup label="Courier Selection" name="courierType" options={[
                  { label: 'Auto', value: 'auto' },
                  { label: 'Let Me Choose', value: 'custom' },
                ]} disabled={pickupLocation?.length === 0} />
              </Grid>
            }

            {courierSelection === 'custom' && (
              <Grid item xs={8}>
                <RHFSelectField
                  variant="filled"
                  width="100%"
                  name="courierPartner"
                  label="Courier Partner"
                  noOptionsText='No courier partner available for this pickup location'
                  options={(newCourier)?.filter(row => !row.isExternal).map((c) => ({
                    label: <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <Typography variant="body2">{c?.name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">{formatCurrency(c?.priceAfterTax)}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                            `TAT: ${c.providerMinTatDays}-${c.providerMaxTatDays} Days` :
                            'TAT: Not Available'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                            `EDD: ${c.minDeliveryDate}-${c.maxDeliveryDate}` :
                            'TAT: Not Available'}
                        </Typography>
                      </Grid>
                    </Grid>,
                    value: c.id
                  }))}
                />
              </Grid>
            )}
            {/* { console.log((allCouriers))} */}

            <Grid item xs={12}>
              {placeOrderFor.length > 0 && placeOrderFor[0]?.channel === 'WOO_COMMERCE' && (
                <LoadingButton
                  // disabled={placeOrderFor.length === 1}
                  loading={isCreating}
                  variant="contained"
                  size="medium"
                  type="submit"
                >
                  Create Shipment
                </LoadingButton>
              )}
              {placeOrderFor.length > 0 && placeOrderFor[0]?.channel !== 'WOO_COMMERCE' && (
                <LoadingButton
                  disabled={placeOrderFor.length === 1 && selectedRows.length === 0}
                  loading={isCreating}
                  variant="contained"
                  size="medium"
                  type="submit"
                >
                  Create Shipment
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </FormProvider>
      </MUIModal>
    </React.Fragment>
  )
}
