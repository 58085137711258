import React, { useEffect, useState } from "react";
import { getMerchant } from '../../helpers/ReduxHelpers';
import { apiConstants, deleteGeneric, getGeneric, postGeneric } from '../../common/constants';
import { useForm } from "react-hook-form";
import { Card, CardContent, Grid } from "@mui/material";
import { Typography, Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, IconButton } from '@mui/material';
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import Loader from "../../common/Loader"
import { useSnackbar } from "notistack";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import ConfirmDialog from "../../common/ConfirmDialog";
import withLayout from "../../hocs/Layout";
import DownloadSampleFile from '../../common/DownloadSampleFile'
import PageTitle from '../../common/PageTitle'
import { ExpandMoreOutlined } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MUIModal from "../../common/MUIModal";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const NotificationConfig = () => {
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [merchantConfig, setMerchantConfig] = useState();
    const [viewData, setViewData] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [reload, setReload] = useState(0);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);


    const columns = [
        {
            field: 'groupName',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Group Name',
            flex: 1,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 15 }}>
                        {row?.data?.groupName}
                    </div>
                )
            },
        },
        {
            field: 'active',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Active',
            flex: 1.2,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                console.log(row, 'rowwwww');
                return (
                    <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 15 }}>
                        {row?.data?.active ? 'Active' : 'Inactive'}
                    </div>
                )
            },
        },
        {
            field: 'action',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Action',
            flex: 0.5,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="View">
                            <IconButton
                                aria-label="details"
                                onClick={() => handleViewOrder(row?.data?.values)}
                                color="primary"
                            >
                                <VisibilityIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Group">
                            <IconButton
                                aria-label="delete"
                                onClick={() => handleCancelOrder(row)}
                                color="error"
                            >
                                <DeleteForeverIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];
    const Columns2 = [
        {
            field: 'sNo',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Serial No.',
            flex: 1,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return row?.id + 1
            },
        },
        {
            field: 'values',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Values',
            flex: 1,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return row?.val
            },
        }
    ]
    const handleViewOrder = (val) => {
        setOpen(true)
        setViewData(val)
    }

    const methods = useForm({
        defaultValues: {
            emails: '',
            whatsapp: '',
        }
    })
    const { handleSubmit: handleSubmitForm, reset } = methods

    const onSubmit = (data) => {
        setLoading(true);
        let form = new FormData();
        form.append("excelFile", data.file[0]);
        form.append("merchantOid", merchantId);
        postGeneric(`${apiConstants.UPLOAD_NOTIFICATION_CONFIG}`, form).then(response => {
            console.log(response);
            enqueueSnackbar('Success', { variant: 'success' })
            reset()
            setReload(reload => reload + 1)
            setLoading(false)
        })
            .catch((err) => {
                console.error(err)
                enqueueSnackbar('Some error occurred', { variant: 'error' })
                setLoading(false)
            })
    }

    const handleCancelOrder = (val) => {
        setDeleteData(val?.data?.values)
        setDeleteConfirm(true)
    }
    useEffect(() => {
        setLoading(true)
        getGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}`).then((res) => {
            setMerchantConfig(res.data?.groupWrapper);
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }, [merchantId, reload])

    const finalValues = viewData.length > 0 ? viewData.map((val, index) => ({ id: index, val })) : []

    const onSwitchAccount = () => {
        console.log(deleteData);
        deleteGeneric(`${apiConstants.DELETE_NOTIFICATION_CONFIG}/?merchantId=${merchantId}`, deleteData).then((res) => {
            console.log(res);

            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })

    };
    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>Notification Details</PageTitle>
                {(isLoading) && <Loader />}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardContent>
                                <FormProvider methods={methods} onSubmit={handleSubmitForm(onSubmit)}>
                                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                        <Grid item xs={12} md={12}>
                                            <RHFFileField
                                                type="file"
                                                label="Upload"
                                                name='file'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} >
                                            <LoadingButton loading={isLoading}
                                                type="submit"
                                                variant="contained"
                                                color="warning"
                                                size="small"
                                            >Upload</LoadingButton>
                                            <DownloadSampleFile fileName={"PUSH_NOTIF_FILE"} label='SAMPLE' excel={'excel'} />
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </CardContent>
                        </Card>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            {merchantConfig?.length > 0 ? merchantConfig.map((val) => {
                                return (
                                    <>
                                        <Accordion sx={{ marginBottom: 1 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Typography variant="h5">{val.type}</Typography>
                                                    </Box>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        The data below provides an details of shipment for which the ticket was created.
                                                    </Typography>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ProzoDataGrid
                                                    columns={columns}
                                                    autoHeight={true}
                                                    rows={(val?.pushNotificationConfig?.length > 0)
                                                        ? val?.pushNotificationConfig.map((data, index) => ({ id: index, data }))
                                                        : []
                                                    }
                                                    sizePerPage={sizePerPage}
                                                    setSizePerPage={setSizePerPage}
                                                    setPage={setPage}
                                                    page={page}
                                                    pagination={true}
                                                    rowHeight={120}
                                                    paginationMode='client'
                                                    // rowCount={Number.MAX_VALUE}
                                                    rowCount={val?.pushNotificationConfig?.length || 0}
                                                    // hideCountNumber={true}
                                                    getRowId={(row) => row.id}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                )
                            })
                                : ''}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <ConfirmDialog
                title=''
                open={deleteConfirm}
                setOpen={setDeleteConfirm}
                onConfirm={() => onSwitchAccount()}>
                Are you sure you want to delete?
            </ConfirmDialog>
            <MUIModal open={isOpen}
                title="View Details"
                handleClose={() => setOpen()}>
                <ProzoDataGrid
                    columns={Columns2}
                    autoHeight={true}
                    rows={finalValues}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    rowHeight={120}
                    paginationMode='client'
                    // rowCount={Number.MAX_VALUE}
                    rowCount={viewData?.length || 0}
                    // hideCountNumber={true}
                    getRowId={(row) => row.id}
                />
            </MUIModal>
        </>
    );
};

export default withLayout(NotificationConfig);
