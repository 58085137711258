import React, { useEffect, useState } from 'react';
import withLayout from '../../../hocs/Layout';
import { numberFormat } from './orderSummary';
import OrderTimeline from './orderTimeline';
import Loader from '../../../common/Loader';
import { apiConstants, get, postGeneric } from '../../../common/constants';
import { useSnackbar } from 'notistack';
import Pods from "../list2/Pods"
import {
    useLocation
} from "react-router-dom";
import { Button, Card, CardContent, CardHeader, Chip, Grid, Typography, Stack, Link, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLazyGetShipmentDetailQuery } from '../../../redux/commonRTK';
import CancelIcon from '@mui/icons-material/Cancel';
import { parseDateAndTime, addressStringWithPrefix, formatDate, formatCurrency, maskPhoneNumber, maskEmail, maskAddress, shouldMaskData, shouldMaskDataPD, getS3SignedUrl, addressStringWithPrefixRAddr } from '../../../helpers/UtilityHelper';
import MUIModal from '../../../common/MUIModal';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import Remarks from '../../Remarks/Remarks';
import PageTitle from '../../../common/PageTitle';
import { OpenInNewOffOutlined } from '@mui/icons-material';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useSelector } from 'react-redux';
import QcDetails from '../list2/QcDetails';

const exclude = "terminalStatus.firstEvent,order_history.courierPartnerId,order_history.courierPartnerEdd,createdDate,courierDetail.key,courierDetail.name,courierDetail.prozoId,itemList.hsn,itemList.itemDescription,clientOrderId,shippingCharge,transactionCharge,giftwrapCharge,dispatchMode,proshipDeliveryTat,courierDeliveryTat,lastStatusUpdateTime,epd,lifeCycleComplete,merchant,courier,merchantPricing.pricingObj,version,orderManifest,courierPricing,overriddenCourierAccountCode,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate";
const columns = [
    {
        field: 'item_name',
        headerName: 'Product name',
        flex: 1.5
    },
    {
        field: 'sku_id',
        headerName: 'SKU',
        flex: 1
    },
    {
        field: 'units',
        headerName: 'Qty',
        flex: 0.8
    },
    {
        field: 'tax',
        headerName: 'Tax',
        flex: 0.8,
        // type: 'number',
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} %`;
        },
    },
    {
        field: 'selling_price',
        headerName: 'Price',
        flex: 0.8,
        // type: "number",
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `₹ ${valueFormatted}`;
        },
    },
    {
        field: 'item_url',
        headerName: 'Item Image',
        flex: 1,
        renderCell: (params) => {
            const { row } = params;
            return (
                <>
                    {row?.item_url ?
                        <Link href={row?.item_url} target='_blank'>Image</Link> : "-"
                    }
                </>
            )
        },
    }
]


const columns2 = [
    {
        field: 'item_name',
        headerName: 'Product name',
        flex: 1.5
    },

    {
        field: 'units',
        headerName: 'Boxes',
        flex: 0.8
    },
    {
        field: 'length',
        headerName: 'Length (cm)',
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;
            return row?.additional?.length;
        },
    },
    {
        field: 'additional.breadth',
        headerName: 'Breadth (cm)',
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;
            return row?.additional?.breadth;
        },
    },
    {
        field: 'additional.height',
        headerName: 'Height (cm)',
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;
            return row?.additional?.height;
        },
    },
    {
        field: 'additional.weight',
        headerName: 'Total Weight (kg)',
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;
            return (row?.additional?.weight / 1000).toFixed(2);
        },
    },
]



function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const OrderDetail = () => {
    // const { data: courierData = [] } = useGetCouriersQuery()
    const { enqueueSnackbar } = useSnackbar();
    let query = useQuery();
    // const dispatch = useDispatch();
    // const [cancelLoading, setLoading] = useState(false);
    // const { orderDetailInfo, loading } = useSelector((state) => state.orderListSlice);
    const [getShipmentDetail, { data, isLoading, isFetching }] = useLazyGetShipmentDetailQuery();
    const waybill = query.get("waybill");
    const [remarks, setRemarks] = useState([])
    const [open, setOpen] = useState(false)
    const [reload, setReload] = useState(0)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    // const [rowCountState, setRowCountState] = useState(remarks?.count || 0);
    const [ndrCalls, setNdrCalls] = useState([]);
    const [ndrQ, setNdrQ] = useState([])
    const [ndrQModal, setNdrQModal] = useState(false)
    const [cancelConfirm, setCancelConfirm] = useState(false);
    const { report } = useSelector(state => state);
    const [row, setRow] = useState();
    const [viewQc, setViewQc] = useState(false)
    const datata = useSelector((state) => state);
    console.log('datata', datata);

    const handleOpen = (row) => {
        setViewQc(true)
        setRow(row)
    }
    // reportData

    const columnsRemarks = [
        {
            field: 'text',
            headerName: 'Remarks',
            width: 750,
        },
        {
            field: 'url',
            headerName: 'Link',
            width: 220,
            renderCell: (col) => {
                if (col.row?.uploads.length > 0)
                    return (
                        <a href={col.row?.uploads[0]?.url} target="_blank" rel="noreferrer">View</a>
                    )
                else {
                    return "-"
                }
            }
        },
        {
            field: 'lastModifiedDate',
            headerName: 'Update Date',
            width: 220,
            renderCell: (col) => formatDate(col.row.lastModifiedDate)
        },
        {
            field: 'createdByUser',
            headerName: 'Updated By',
            width: 220,
            // renderCell: (col) => formatDate(col.row.lastModifiedDate)
        },
    ]

    const ndrCallcolumns = [
        {
            field: 'ndrresults', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Results', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.ndr_result}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'ndr_outcome', headerAlign: "left", hideSortIcons: true, headerName: 'Outcome', flex: 1, renderCell: (params) => {
                const { row } = params;
                const outcome = row?.ndr_outcome === true ? 'TRUE' : 'FALSE'
                const color = row?.ndr_outcome === true ? "success" : "error";
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Chip size="small" color={color} label={outcome} />
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'startTime', headerAlign: "left", hideSortIcons: true, headerName: 'Call time', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                return formatDate(row.call_start_time)
            },
        },
        {
            field: 'callDuration', headerAlign: "left", hideSortIcons: true, headerName: 'Call Duration (Mins)', flex: 1, renderCell: (params) => {
                const { row } = params;
                return row?.call_duration
            },
        },
        {
            field: 'recordingURL', headerAlign: "left", hideSortIcons: true, headerName: 'Recordings', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link href={row.recording_url} target="_blank" rel="noopener noreferrer" >
                        {row.recording_url ? "Play" : "-"}
                    </Link>
                )
            },
        },
        {
            field: 'failedOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Failed Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row?.call_responses && row.call_responses.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setNdrQ(row.call_responses);
                                        setNdrQModal(true);
                                    }}
                                >
                                    Questions asked to Customer
                                </Button>
                            </Grid>

                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        }
    ];
    const columnsNDRActionLog = [
        {
            field: 'ndrAction',
            hideSortIcons: true,
            headerName: 'Action Type',
            flex: 0.8
        },
        {
            field: 'reattemptNumber',
            hideSortIcons: true,
            headerName: 'Reattempt Number',
            flex: 0.6
        },
        {
            field: 'actionTakenBy',
            hideSortIcons: true,
            headerName: 'Action Taken By',
            flex: 1.2
        },
        {
            field: "reattemptRequestDate",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Reattempt Request Date",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return row.ndrCurrentAttemptDate ? parseDateAndTime(row?.ndrCurrentAttemptDate, true) : ""
            },
        },
        {
            field: 'actionDate',
            hideSortIcons: true,
            headerName: 'Action Date',
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.actionDate)
            },
        },
        {
            field: 'updatedAddress',
            hideSortIcons: true,
            headerName: 'Updated Address',
            flex: 1.5,
        },
        {
            field: 'landmark',
            hideSortIcons: true,
            headerName: 'Updated Landmark',
            flex: 1
        },
        {
            field: 'updatedContactNumber',
            hideSortIcons: true,
            headerName: 'Update Contact',
            flex: 1
        },
    ]
    const columnsNPRActionLog = [
        {
            field: 'nprAction',
            hideSortIcons: true,
            headerName: 'Action Type',
            flex: 0.8
        },
        {
            field: 'actionTakenBy',
            hideSortIcons: true,
            headerName: 'Action Taken By',
            flex: 1.2
        },
        {
            field: "nprCurrentAttemptDate",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "NPR Current Attempt Date",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return row.nprCurrentAttemptDate ? parseDateAndTime(row?.nprCurrentAttemptDate, true) : ""
            },
        },
        {
            field: 'actionDate',
            hideSortIcons: true,
            headerName: 'Action Date',
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.actionDate)
            },
        },
        {
            field: 'landmark',
            hideSortIcons: true,
            headerName: 'Landmark',
            flex: 0.7,
        },
        {
            field: 'updatedAddress',
            hideSortIcons: true,
            headerName: 'Updated Address',
            flex: 0.7,
        },
        {
            field: 'updatedContactNumber',
            hideSortIcons: true,
            headerName: 'Updated Contact No.',
            flex: 0.7,
        },
    ]

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setViewQc(false)
    };

    // function getCourierLogo(courierId) {
    //     if (courierId) {
    //         let result = _.find(courierData, function (obj) {
    //             if (obj.id === courierId) {
    //                 return obj.logoUrl;
    //             }
    //         });
    //         return result?.logoUrl;
    //     }
    // }
    const orderDetails = data?.data[0] || null;
    const ndrActionLog = data?.data[0]?.ndrData?.actionUpdateLog || [];
    const nprActionLog = data?.data[0]?.nprData?.actionUpdateLog || [];
    const andfilter = [
        {
            "field": "awbNumber",
            "operator": "eq",
            "value": waybill
        }
    ];
    useEffect(() => {
        getShipmentDetail({
            "excludes": exclude, "andfilter": andfilter, "offset": 0, "limit": 1
        }).then((res) => {
            let data = res.data;
            let orderId = data?.data[0]?.id
            get(apiConstants.BASE_LOCAL + apiConstants.REMARKS + "/ORDER/" + orderId).then((res) => {
                const data = res.data?.remarks ? res.data?.remarks.reverse() : [];
                setRemarks(data)
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waybill, reload]);

    useEffect(() => {
        const payload = {
            "collection": "futworks_ndr_recording",
            "limit": 100,
            "skipCount": true,
            "countOnly": false,
            "andfilter": [
                {
                    "field": "awb_number",
                    "operator": "eq",
                    "value": waybill
                }
            ]
        }
        postGeneric(apiConstants.REPORT_GENERIC_SEARCH, payload).then((res) => {
            // console.log(res?.data?.data)
            setNdrCalls(res?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading || isFetching) {
        return <Loader />
    }
    const itemDetails = ((orderDetails) ? orderDetails.item_list : [])
    // const Length = ((orderDetails && orderDetails.shipment_detail[0].item_length) ? orderDetails.shipment_detail[0].item_length : "")
    // const Breadth = ((orderDetails && orderDetails.shipment_detail[0].item_breadth) ? orderDetails.shipment_detail[0].item_breadth : "")
    // const Height = ((orderDetails && orderDetails.shipment_detail[0].item_height) ? orderDetails.shipment_detail[0].item_height : "")
    // const item_detail = (orderDetails && orderDetails.order_history) ? (orderDetails.order_history) : [];
    const orderStatus = ((orderDetails && orderDetails.orderStatus) ? orderDetails.orderStatus : "");
    const payment_mode = ((orderDetails && orderDetails.payment_mode) ? orderDetails.payment_mode : "");
    const color = payment_mode === 'COD' ? "error" : "success";
    const currentStatus = ((orderDetails && orderDetails.orderStatus) ? orderDetails.orderStatus : "");
    // const printLable = () => {

    //     saveAs(
    //         orderDetails.label_url,
    //         `${orderDetails.awb_number}.pdf`
    //     );
    // };

    function getStatusColor() {
        if (['FAILED_DELIVERY', "SHIPMENT_HELD", 'RTO', "RTO_INTRANSIT"].includes(currentStatus)) {
            return "error"
        }
        if (['DELIVERED', "RTO_DELIVERED"].includes(currentStatus)) {
            return "#0abb87"
        }
        return "primary"
    }

    // zfunction getHeaderChip(){
    //     let headerChip={};
    //     if(['DELIVERED'].includes(currentStatus)){
    //         headerChip.key="Delivery Time"
    //         headerChip.color="#0abb87"
    //         headerChip.value= getTAT(orderDetails)
    //     }
    //     else{

    //     }
    //     return headerChip;

    // }
    const toggleCancel = () => {
        setCancelConfirm(true);
    };
    const cancelOrder = () => {
        // setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.CANCEL_ORDER}?waybill=${query.get("waybill")}`).then(response => {
            // setLoading(false);
            let rs = response.data;
            if (rs.meta && rs.meta.status && rs.meta.status === "200") {
                enqueueSnackbar("Cancelled successfully", { variant: 'success' });
                setReload(reload + 1);
            }
            if (rs.meta && rs.meta.status && rs.meta.status === "350") {
                enqueueSnackbar("Unable to cancel with courier partner at this point", { variant: 'warning' });
            }
            else if (rs.meta && rs.meta.status && rs.meta.status === "603") {
                enqueueSnackbar("Courier partner API cancellation not supported", { variant: 'error' });
            }
            else if (rs.meta && rs.meta.status && rs.meta.status !== "200" && rs.meta.status !== "350" && rs.meta.status !== "603") {
                enqueueSnackbar(rs.meta.message, { variant: 'error' });
            }
            // dispatch(getOrderLists({ "andfilter": andfilter, "offset": 0, "limit": 1 }));
        })
            .catch(err => {
                console.error(err);
            });
    }

    const openDocument = async (url, type) => {
        const newUrl = await getS3SignedUrl(url, {
            awbNumber: orderDetails?.awb_number,
            'docType': type
        }, type)
        window.open(newUrl, true)
    }

    const handleFetchLabel = (reference) => {
        if (reference) {
            get(`${apiConstants.BASE_LOCAL}${apiConstants.B2B_ORDER_FETCH_LABEL}?orderReference=${reference}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Label Fetched Successfully', { variant: 'success' })
                    setReload(reload + 1)
                }
                else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' })
                }
            })
        }
    }

    return (
        <div id="main-content" className="container-fluid">
            <ConfirmDialog
                title=''
                open={cancelConfirm}
                setOpen={setCancelConfirm}
                onConfirm={() => cancelOrder()}
            >
                Are you sure you want to cancel this order ?
            </ConfirmDialog>
            <MUIModal open={viewQc} handleClose={handleClose} children={<QcDetails data={row} />} title={"Qc Link"} maxWidth="sm" />
            <PageTitle>Shipment Details / {orderDetails?.awb_number}</PageTitle>
            <Grid container spacing={2} paddingBottom={2}>
                <Grid item xs={6}>
                    <Card sx={{ minHeight: 90, maxHeight: 90 }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography variant='h5'>{(orderDetails && orderDetails.shippingLabelId) ? orderDetails.shippingLabelId : ""}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography variant='subtitle2'>Order Reference Number</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minHeight: 90, maxHeight: 90 }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography color={getStatusColor()} variant='h5'>{currentStatus.replaceAll("_", " ")}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography variant='subtitle2'>Current Status</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={4}>
                    <Card sx={{ minHeight: 100, maxHeight: 100 }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2' color={"black"}>{getHeaderChip()?.value}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
            <Grid paddingBottom={2}>
                <Card>
                    <CardHeader title="Order Details" sx={{ fontWeight: 800 }} />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.shippingLabelId) ? orderDetails.shippingLabelId : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.channel_name) ? orderDetails.channel_name : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>AWB Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.awb_number) ? orderDetails.awb_number : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>RTO AWB Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.rto_awb) ? orderDetails.rto_awb : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Status</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={currentStatus} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Payment Mode</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={color} label={payment_mode} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{orderDetails?.courierDetail?.parent.includes('Blended') ? orderDetails.actualCourierProviderName : orderDetails?.courierDetail?.parent}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Value</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.invoice_value) ? numberFormat(orderDetails.invoice_value) : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.order_date) ? parseDateAndTime(orderDetails.order_date, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.invoice_number) ? orderDetails.invoice_number : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Cost</Typography></Grid>
                            <Grid item xs={6} md={3}>
                                <Typography variant='subtitle2'>
                                    {(orderDetails && orderDetails.merchant_pricing?.price) ? numberFormat(orderDetails.merchant_pricing?.price) : ""}
                                    {orderDetails && orderDetails.merchant_pricing?.price && (
                                        <Tooltip title="Shipment Price may change post weight discrepancy adjustments">
                                            <span style={{ color: 'red' }}><sup>*</sup></span>
                                        </Tooltip>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Zone</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.merchantZone) ? orderDetails.merchantZone : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>EDD</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.edd) ? parseDateAndTime(orderDetails.edd, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Delivery Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.actualDeliveryTime) ? parseDateAndTime(orderDetails.actualDeliveryTime, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Dead Weight</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.shipment_detail[0].item_weight) ? ((orderDetails.shipment_detail[0].item_weight) / 1000).toFixed(2) : "")} kg</Typography></Grid>
                            {orderDetails && orderDetails?.shipment_type !== 'B2B' && (
                                <>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Vol Weight</Typography></Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant='subtitle2'>
                                            {((orderDetails && orderDetails.shipment_detail[0]) ? ((orderDetails.shipment_detail[0].item_length * orderDetails.shipment_detail[0].item_breadth * orderDetails.shipment_detail[0].item_height) / 5000) : "")}kg
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>QC Verified</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{orderDetails && orderDetails?.qc_type ? "Yes" : "No"}</Typography></Grid>
                            {orderDetails?.qcDetails && (
                                <>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>QC Images</Typography></Grid>
                                    <Grid item xs={6} md={3}>{
                                        <Button color='warning' variant='contained' size='small' onClick={() => handleOpen(orderDetails)}>View</Button>
                                    }</Grid>
                                </>
                            )}
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Price RTO</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.merchant_pricing_rto && orderDetails.merchant_pricing_rto.price) ? formatCurrency(orderDetails.merchant_pricing_rto.price) : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Label</Typography></Grid>
                            <Grid item xs={6} md={3} style={{ padding: '10px' }}>
                                <Tooltip title="Link expires after 7 days of generation" placement="top">
                                    <Link
                                        href={orderDetails?.shipment_type === "B2B" ? orderDetails?.b2bPackageLabelUrl : orderDetails?.label_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant='subtitle2'
                                            style={{
                                                color: !orderDetails?.label_url ? '' : '#007bff',
                                                textDecoration: !orderDetails?.label_url ? "" : 'underline'
                                            }}
                                        >
                                            View Label
                                            <OpenInNewOffOutlined fontSize="small" style={{ marginLeft: '5px' }} />
                                        </Typography>
                                    </Link>
                                </Tooltip>
                            </Grid>

                            {orderDetails?.invoice_url &&
                                <>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice</Typography></Grid>
                                    <Grid item xs={6} md={3}>
                                        <Tooltip title="Link refreshes after 7 days of generation" placement="top">
                                            <Button onClick={() => openDocument(orderDetails?.invoice_url, 'INVOICE')} style={{ marginLeft: '-10px' }}>
                                                <Typography
                                                    variant='subtitle2'
                                                    style={{ color: '#007bff', textDecoration: 'underline' }}
                                                >
                                                    View Invoice
                                                    <OpenInNewOffOutlined fontSize="small" style={{ marginLeft: '5px' }} />
                                                </Typography>
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </>
                            }
                            {orderDetails && orderDetails?.shipment_type === "B2B" && orderDetails.label_url && orderDetails.label_url !== '' && (
                                <>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>LR Copy</Typography></Grid>
                                    <Grid item xs={6} md={3} style={{ padding: '10px' }}>
                                        <Tooltip title="Link expires after 7 days of generation" placement="top">
                                            <Link
                                                href={orderDetails?.shipment_type === "B2B" ? orderDetails?.label_url : orderDetails?.b2bPackageLabelUrl}
                                                // href={orderDetails?.b2bPackageLabelUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Typography
                                                    variant='subtitle2'
                                                    style={{ color: '#007bff', textDecoration: 'underline' }}
                                                >
                                                    LR Copy
                                                    <OpenInNewOffOutlined fontSize="small" style={{ marginLeft: '5px' }} />
                                                </Typography>
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                </>
                            )}
                            {/* {orderDetails && orderDetails?.shipment_type === "B2B" && (!orderDetails.label_url || orderDetails.label_url === '') && ( */}
                            {/* )} */}
                            {orderDetails && orderDetails.manifestDetails && (
                                <>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Manifest Number</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.manifestDetails ? orderDetails.manifestDetails?.manifestNumber : "")}</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Manifest Link</Typography></Grid>
                                    <Grid item xs={6} md={3}>
                                        <Link target='_blank' href={orderDetails?.manifestDetails?.manifestUrl}>Manifest</Link>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6} md={12}>
                                <>
                                    <Tooltip title="Fetch Label">
                                        <Button variant='contained' onClick={() => handleFetchLabel(orderDetails.reference)} color="primary" size="small">
                                            Refetch Label
                                        </Button>
                                    </Tooltip>
                                </>
                            </Grid>
                            {/* <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Tracking Link</Typography></Grid>
                            <Grid item xs={6} md={3}><Button href={`/tracking-detail?waybill=${orderDetails?.awb_number}`}>Track Order</Button></Grid> */}
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
            {
                orderDetails?.isWDSetteled === true &&
                <Grid paddingBottom={2}>
                    <Card>
                        <CardHeader title="Weight Dispcrepancy" sx={{ fontWeight: 800 }} />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Weight Discrepancy Status </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdStatus) ? orderDetails?.wdStatus : ""} </Typography></Grid>
                                {/* <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Update Courier Price </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdChargedPriceCourier) ? formatCurrency(orderDetails?.wdChargedPriceCourier) : ""} </Typography></Grid> */}
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Updated Merchant Price </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdChargedPriceMerchant) ? formatCurrency(orderDetails?.wdChargedPriceMerchant) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Updated Weight </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdChargedWeight) ? ((orderDetails?.wdChargedWeight) / 1000) + " kg" : ""} </Typography></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            }
            <Grid container spacing={2} paddingBottom={2}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ minHeight: 250, maxHeight: 350 }}>
                        <CardHeader title="Pickup Details" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Pickup Name</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.pickup_details?.from_name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Phone Number </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskDataPD(report, orderDetails) ?
                                        maskPhoneNumber(orderDetails?.pickup_details?.from_phone_number) :
                                        orderDetails?.pickup_details?.from_phone_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Email ID</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskDataPD(report, orderDetails) ?
                                        maskEmail(orderDetails?.pickup_details?.from_email) :
                                        orderDetails?.pickup_details?.from_email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Address</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{
                                        shouldMaskDataPD(report, orderDetails) ?
                                            maskAddress(addressStringWithPrefix("from", orderDetails?.pickup_details)) :
                                            addressStringWithPrefix("from", orderDetails?.pickup_details)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{ minHeight: 250, maxHeight: 350 }}>
                        <CardHeader title="Delivery Details" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Customer Name</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.delivery_details?.to_name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Phone Number</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {/* {orderDetails && orderDetails.terminalStatus && (
                                        <Typography component='span'>xxxxxxxxxx</Typography>
                                    )} */}
                                    {orderDetails && (
                                        <Typography component='span'>{shouldMaskData(report, orderDetails) ?
                                            maskPhoneNumber(orderDetails?.delivery_details?.to_phone_number) :
                                            orderDetails?.delivery_details?.to_phone_number}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Email ID</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskData(report, orderDetails) ?
                                        maskEmail(orderDetails?.delivery_details?.to_email) : orderDetails?.delivery_details?.to_email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Shipping Address</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskData(report, orderDetails) ?
                                        maskAddress(addressStringWithPrefix("to", orderDetails?.delivery_details)) :
                                        addressStringWithPrefix("to", orderDetails?.delivery_details)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card sx={{ minHeight: 250, maxHeight: 350 }}>
                        <CardHeader title="Return Details" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Return Name</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.return_details?.name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Phone Number</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {/* {orderDetails && orderDetails.terminalStatus && (
                                        <Typography component='span'>xxxxxxxxxx</Typography>
                                    )} */}
                                    {orderDetails && (
                                        <Typography component='span'>{shouldMaskDataPD(report, orderDetails) ? maskPhoneNumber(orderDetails?.return_details?.phone_number) : orderDetails?.return_details?.phone_number}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Email ID</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskDataPD(report, orderDetails) ? maskEmail(orderDetails?.return_details?.email) : orderDetails?.return_details?.email}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Return Address</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskDataPD(report, orderDetails) ? maskAddress(orderDetails?.return_details?.address) : addressStringWithPrefixRAddr(orderDetails?.return_details)}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid paddingBottom={2}>
                <Card>
                    <Grid container spacing={1} alignItems="center" justify="center" sx={{ paddingBottom: 2 }}>
                        {itemDetails && itemDetails.length > 0 && (
                            <Grid item xs={12}>
                                <DataGrid
                                    columns={orderDetails?.shipment_type === 'B2B' ? columns2 : columns}
                                    rows={itemDetails?.length > 0 ? itemDetails.map((val, index) => ({ ...val, id: index })) : []}
                                    getRowId={(row) => row?.id}
                                    hideFooterPagination={true}
                                    autoHeight
                                    disableColumnMenu
                                    disableColumnFilter
                                    disableSelectionOnClick
                                />
                            </Grid>
                        )}
                    </Grid>

                </Card>
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title="All Shipment Scans" />
                    <Grid sx={{ padding: 6 }}>
                        <OrderTimeline orderDetailInfo={orderDetails ? orderDetails : []} cancelledBy={orderDetails?.cancelledBy} />
                    </Grid>
                </Card>
            </Grid>
            {
                orderDetails && orderDetails.ndrData && (
                    <Grid sx={{ paddingBottom: 2 }}>
                        <Card>
                            <CardHeader title="NDR Details" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.ndrData) ? formatDate(orderDetails.ndrData?.ndrReportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Re-reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.ndrData) ? formatDate(orderDetails.ndrData?.ndrRereportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current NDR Status</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.ndrData) ? orderDetails.ndrData?.ndrStatus : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Age</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.ndrData && orderDetails.ndrData.ndrAge) ? orderDetails.ndrData?.ndrAge + " days" : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>OFD Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.ndrData) ? orderDetails.ndrData?.ofdCount : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reattempt Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.ndrData) ? orderDetails.ndrData?.reAttemptCount : ""} </Typography></Grid>

                                    {ndrActionLog?.length > 0 && (
                                        <>
                                            <br />
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"}>NDR Action Log </Typography></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <ProzoDataGrid
                                                columns={columnsNDRActionLog}
                                                getRowId={(row) => Math.random()}
                                                rows={ndrActionLog}
                                                hideFooterPagination={true}
                                                sizePerPage={sizePerPage}
                                                setSizePerPage={setSizePerPage}
                                                setPage={setPage}
                                                page={page}
                                                pagination={true}
                                                rowCount={ndrActionLog?.length || 0}
                                                autoHeight
                                            />
                                        </>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            }

            {
                ndrCalls?.length > 0 && (
                    <Grid item xs={12} paddingBottom={2}>
                        <Card sx={{ paddingBottom: '2' }}>
                            <CardHeader title="NDR Call Log" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <ProzoDataGrid
                                    columns={ndrCallcolumns}
                                    autoHeight={true}
                                    rows={ndrCalls || []}
                                    pagination={false}
                                    hideFooterPagination={true}
                                    rowHeight={120}
                                    getRowId={(row) => row?.awb_number + row?.created_at}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )
            }

            {
                orderDetails?.pods?.length > 0 && (
                    <Grid item xs={12} paddingBottom={2}>
                        <Card>
                            <CardHeader title="POD" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Pods id={orderDetails} />
                            </CardContent>
                        </Card>
                    </Grid>
                )
            }
            {
                orderDetails && orderDetails.nprData && (
                    <Grid sx={{ paddingBottom: 2 }}>
                        <Card>
                            <CardHeader title="NPR Details" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NPR Reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.nprData) ? formatDate(orderDetails.nprData?.nprReportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NPR Re-reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.nprData) ? formatDate(orderDetails.nprData?.nprRereportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current NPR Status</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.nprData) ? orderDetails.nprData?.nprStatus : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NPR Age</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.nprData && orderDetails.nprData.nprAge) ? orderDetails.nprData?.nprAge + " days" : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>OFP Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.nprData) ? orderDetails.nprData?.ofPCount : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reattempt Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.nprData) ? orderDetails.nprData?.reAttemptCount : ""} </Typography></Grid>

                                    {nprActionLog?.length > 0 && (
                                        <>
                                            <br />
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"}>NDR Action Log </Typography></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <ProzoDataGrid
                                                columns={columnsNPRActionLog}
                                                getRowId={(row) => Math.random()}
                                                rows={nprActionLog}
                                                hideFooterPagination={true}
                                                sizePerPage={sizePerPage}
                                                setSizePerPage={setSizePerPage}
                                                setPage={setPage}
                                                page={page}
                                                pagination={true}
                                                rowCount={nprActionLog?.length || 0}
                                                autoHeight
                                            />
                                        </>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            }

            <Grid sx={{ paddingTop: 2 }}>
                <Card>
                    <CardHeader
                        title="Remarks"
                        action={<Button variant="contained" onClick={handleClickOpen}>Add Remarks</Button>}
                    />

                    <MUIModal open={open} hideClose={true} children={<Remarks id={orderDetails?.id} on={"ORDER"}
                        setReload={setReload}
                        reload={reload}
                        handleClose={handleClose}
                        setOpen={setOpen} />}
                        title={"Update Remarks"} />
                    <CardContent>
                        {remarks?.length > 0 &&
                            <ProzoDataGrid
                                columns={columnsRemarks}
                                getRowId={(row) => row?.id}
                                rows={remarks.length > 0 ? remarks.map((val, index) => ({ ...val, id: index })) : []}
                                hideFooterPagination={false}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                rowCount={remarks?.count || 0}
                                autoHeight
                            />
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 3 }}>
                {/* <Button variant="text" onClick={printLable} ><LocalPrintshopIcon />Print</Button> */}
                {!orderDetails?.lifeCycleComplete && (
                    <Button variant="text" onClick={toggleCancel} disabled={orderStatus === "CANCELLED_ORDER" || orderStatus === "DELIVERED" || orderStatus === "FAILED_DELIVERY"}><CancelIcon />Cancel order</Button>
                )}
                {/* {
                    (orderStatus === 'DELIVERED') &&
                    <Button variant="text" href={`/orders/create-reverse/${orderDetails.awb_number}`}><AddCircleOutlineIcon />Create Reverse Order</Button>
                } */}
            </Grid>

            <MUIModal
                open={ndrQModal}
                title="Call Details"
                maxWidth={"lg"}
                handleClose={() => setNdrQModal(false)}
            >
                <ProzoDataGrid
                    // autoHeight={false}
                    columns={[{
                        field: "question", headerName: "Question", flex: 3
                    }, {
                        field: "answer", flex: 1, headerName: "Answer"
                    },]}
                    autoHeight={true}
                    rows={ndrQ || []}
                    pagination={false}
                    hideFooterPagination={true}
                    rowHeight={120}
                    getRowId={(row) => Math.random()}
                />
            </MUIModal>
        </div >


    );
}

export default withLayout(OrderDetail);