import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './app/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ThemeProvider from "./app/theme"
import { ToastContainer } from 'react-toastify';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './routes/AppRoutes'
import { PageTitleProvider } from './app/common/PageTitleContext';
// import { SidebarToggleProvider } from './app/components/SidebarToggleContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <ToastContainer />
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}>
          <PageTitleProvider>
            {/* <SidebarToggleProvider> */}
              <AppRoutes />
            {/* </SidebarToggleProvider> */}
          </PageTitleProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
