import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import InfoBox from "../../../../common/InfoBox"
import { DualAxisChart } from "../../../../components/charts/CompositChart"
import { formatNumber,numberPrecision } from "../../../../helpers/UtilityHelper"
import _ from 'lodash'


const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    yaxis: {
        left: {
            label: 'Avg Shipment Cost',
            data: [
                {
                    dataKey: 'perShipmentCost',
                    label: 'Per Shipment Shipping Cost',
                    chartType: 'line'
                },
            ],
        },
        // right: {
        //     label: 'Shipping Cost %',
        //     data: [
        //         {
        //             dataKey: 'perShipmentCost',
        //             unit: '%',
        //             label: 'Shipments Cost %',
        //             chartType: 'line',
        //             fill: '#C37DC4',
        //         },
        //     ],
        // },
    },
}
const calculateTotalShippingCost = (shipments) => {
    let k= _.sumBy(shipments, (shipment) => parseFloat(shipment.price) || 0);
    let k1= _.sumBy(shipments, (shipment) => parseFloat(shipment.priceRTO) || 0);
    let totalPrice = k+k1;
    return totalPrice;
};

export default function ShipmentsCostOverview({ data }) {
    const totalShippingCost = calculateTotalShippingCost(data.allRegisteredData);
    return (
        <Card elevation={1}>
            <CardHeader title="Shipping Cost Overview"  subheader="This per shipment cost (incl GST) data doesn't factor if any adjustments in the billing have been made manually after generating the bill from ProShip. Any CNs issued to the brand but not uploaded on ProShip Billing Module also won't be factored. For exact per shipment cost, please refer to exact billing data.
            The shipment cost includes the charges for forward and return (if applicable) leg of the shipment journey."/>
            <CardContent>
                <Grid container spacing={2}>
                     <Grid item xs={3}>
                        <InfoBox header="Total Forward Shipments" value={formatNumber(data.awbRegisteredOrderCount)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Total Shipping Cost" value={(numberPrecision(totalShippingCost,0))} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Avg Shipping Cost" value={(numberPrecision(totalShippingCost/data.awbRegisteredOrderCount,0))} />
                    </Grid>
                    {/*
                    <Grid item xs={3}>
                        <InfoBox header="Picked Shipments" value={formatNumber(data.pickedUpOrderCount)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Pending Pickup Shipments" value={formatNumber(data.awbRegisteredOrderCount - data.pickedUpOrderCount)} />
                    </Grid> */}
                    {/* <Grid item xs={2}>
                        <InfoBox header="Forward Shipments" value={formatNumber(data.forwardOrderCount)} />
                    </Grid>
                    <Grid item xs={2}>
                        <InfoBox header="Reverse Shipments" value={formatNumber(data.reverseOrderCount)} />
                    </Grid> */}
                    {/* <Grid item xs={3}>
                        <InfoBox header="Prepaid / COD %" value={numberPrecision(data.prepaidPercentage) +" / " + numberPrecision(data.codPercentage)} />
                    </Grid> */}
                  
                    {/* <Grid item xs={2}>
                        <InfoBox header="Forward Delivered %" value={numberPrecision(data.allForwardDeliveredPercentage)} />
                    </Grid> */}
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <DualAxisChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}