import { Backdrop, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { login, loginUserDataSlice } from '../../redux/userSlice';
import { fetchReportData } from '../../redux/actions/reportAction';
import { apiConstants } from '../../common/constants';
import axios from 'axios';
import { decodeAuthToken } from '../../helpers/UtilityHelper';
import { useSnackbar } from 'notistack';

function HandleGoogleRedirect() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        const handleGoogleLogin = async (code) => {
            try {
                let config = { headers: { 'Content-Type': 'application/json' } };
                const validateURL = `${apiConstants.BASE_LOCAL}${apiConstants.VALIDATE_GOOGLE_AUTH_CODE}`;
                const { data } = await axios.get(validateURL + "?code=" + code)
                localStorage.setItem('userToken', data.accessToken);
                const { merchantOid, merchantList, roles } = decodeAuthToken(data.accessToken);
                dispatch(loginUserDataSlice({ ...data }))

                if (roles?.includes('ROLE_PROSHIPADMIN') && (merchantList === undefined || merchantList?.length === 0 || merchantList?.length > 0)) {
                    let url = "/loginuser";
                    navigate(url);
                } else {
                    let merchantData;
                    config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + data.accessToken,
                        },
                    };
                    let merchantUrl =
                        apiConstants.BASE_LOCAL + apiConstants.GET_MERCHANT_SEARCH;
                    const val = await axios.post(
                        merchantUrl,
                        {
                            offset: 0,
                            limit: 1,
                            orfilter: [],
                            andfilter: [{ field: "id", operator: "eq", value: merchantOid }],
                            excludes: "mappedOrderRule,sla",
                        },
                        config
                    );
                    merchantData = val?.data.data[0];
                    // delete merchantData.codRemittanceConfig;
                    delete merchantData.mappedOrderRule;
                    delete merchantData.wallet;
                    delete merchantData.pickup_details;
                    delete merchantData.bank_details;
                    delete merchantData.alertSubscription;
                    delete merchantData.gstDetails;
                    delete merchantData.merchantWebHooks;
                    delete merchantData.activeSlas;
                    delete merchantData.alertSubscriptionB2B;
                    const userInfo = { ...data, merchant: merchantData };
                    dispatch(login(userInfo));
                    dispatch(fetchReportData(merchantOid));
                }
            } catch (error) {
                console.log(error);
                if (error.response.status >= 500 || error.response.status === 499) {
                    enqueueSnackbar("Server Error", { variant: "error" });
                }
                if (error.response.status === 401 || error.response.status === 400) {
                    navigate("/unAuthorized");
                    enqueueSnackbar("Incorrect username or password", { variant: "error" });
                }
                else {
                    enqueueSnackbar(error?.response?.data || error.message, { variant: "error" });
                }
            }
        }
        if (code) {
            handleGoogleLogin(code)
        } else {
            console.error('Authorization code not found in the URL');
        }
    }, [navigate]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" />
                <Typography sx={{ mt: 2 }}>Please wait while we validate your details!</Typography>
            </Box>
        </Backdrop>
    )
}

export default HandleGoogleRedirect;