import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import withLayout from '../../hocs/Layout';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { apiConstants, postGeneric, get } from '../../common/constants';
import { mergeServicability } from '../../helpers/UtilityHelper';
import { CSVLink } from 'react-csv';
import ConfirmDialog from '../../common/ConfirmDialog';
import Loader from '../../common/Loader';
import * as XLSX from 'xlsx';
import PageTitle from '../../common/PageTitle';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Clear, Done } from '@mui/icons-material';
import { getMerchant } from '../../helpers/ReduxHelpers';


const headers = [
    { label: "Courier", key: "account_code" },
    { label: "Prepaid", key: "serviceable.PREPAID" },
    { label: 'COD', key: 'serviceable.COD' },
    { label: 'Express', key: 'express' },
    { label: 'Surface', key: 'surface' },
    { label: 'Reverse', key: 'reverse' },
];

const Serviceability = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false);
    const [message, setMessage] = useState();
    const [result, setResult] = useState()
    const [bulkS, setBulkS] = useState(false);
    const merchant = getMerchant();
    const { merchantId } = merchant

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            const { data: serviceabilityPincodes } = await postGeneric(`${apiConstants.SERVICEABILITY_PINCODE}?merchantId=${merchantId}&isAdminPanelCall=${false}`, [data])
            const { data: courierList } = await postGeneric(apiConstants.COURIER_LIST, {
                "andfilter": [{ "field": "isDeleted", "operator": "eq", "value": "0" }],
                offset: 0,
                limit: 100,
            })

            let serviceabilityData = [];

            if (serviceabilityPincodes?.result) {
                serviceabilityData = mergeServicability(serviceabilityPincodes.result, courierList)
            }

            setResult(serviceabilityData)

        } catch (e) {
            setMessage('Something went wrong while checking serviceability')
            // enqueueSnackbar(e?.response?.data?.message || e.message, { variant: 'error' })
        }
        setIsLoading(false);
    };

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                pickup_pincode: Yup.number().typeError("Pickup pincode must be a number").integer("Pickup pincode must be an number").required("Pickup pincode is required"),
                drop_pincode: Yup.number().typeError("Destination pincode must be a number").integer("Destination pincode must be an number").required("Destination pincode is required")
            })
        ),
        defaultValues: {
            pickup_pincode: '',
            drop_pincode: ''
        }
    })
    const { handleSubmit: handleSubmitForm } = methods;

    const handleBulkServiceabilityDownload = () => {
        setIsLoading2(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.BULK_MERCHANT_SERVICEABILITY}?merchantOid=${merchantId}`).then(response => {
            if (response.data.meta && response.data.meta.status === 200) {
                let data = response.data.result;
                const workbook = XLSX.utils.book_new();
                const codDropData = data?.drop_pincodes.COD.map((pincode) => [pincode]);
                const codPrepaidData = data?.drop_pincodes.PREPAID.map((pincode) => [pincode]);
                const pickupPrepaidData = data?.pickup_pincodes.PREPAID.map((pincode) => [pincode]);

                const codDropWorksheet = XLSX.utils.aoa_to_sheet([['COD Drop'], ...codDropData]);
                const codPrepaidWorksheet = XLSX.utils.aoa_to_sheet([['Prepaid Drop'], ...codPrepaidData]);
                const pickupPrepaidWorksheet = XLSX.utils.aoa_to_sheet([['Pickup Prepaid'], ...pickupPrepaidData]);


                XLSX.utils.book_append_sheet(workbook, codDropWorksheet, 'COD Drop');
                XLSX.utils.book_append_sheet(workbook, codPrepaidWorksheet, 'Prepaid Drop');
                XLSX.utils.book_append_sheet(workbook, pickupPrepaidWorksheet, 'Pickup Prepaid');

                // Generate the Excel file
                const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
                saveExcelFile(excelBuffer, 'bulk_serviceability.xlsx');
                setIsLoading2(false);
            } else {
                setResult([])
                setMessage('Something went wrong while checking serviceability')
                setIsLoading2(false);
            }
        }).catch((error) => {
            // Handle the error here
            console.error('Error fetching data:', error);
            setMessage('Something went wrong while downloading bulk serviceability')
            setResult([])
            setIsLoading2(false);
        });
    };

    const saveExcelFile = (buffer, filename) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // For IE browser
            window.navigator.msSaveBlob(blob, filename);
        } else {
            // For other browsers
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', filename);
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const columns = [{
        field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 1, renderCell: (params) => {
            const { row } = params;
            return row.account_code
        },
    },
    {
        field: 'Prepaid', hideSortIcons: true, headerAlign: 'left', headerName: 'Prepaid', flex: 1, renderCell: (params) => {
            const { row } = params;
            if (row.serviceable.PREPAID) {
                return <Done color='success' />;
            } else {
                return <Clear color='error' />;
            }
        },
    },
    {
        field: 'COD', hideSortIcons: true, headerAlign: 'left', headerName: 'COD', flex: 1, renderCell: (params) => {
            const { row } = params;
            if (row.serviceable.COD) {
                return <Done color='success' />;
            } else {
                return <Clear color='error' />;
            }
        },
    },
    // {
    //     field: 'express',  hideSortIcons: true,headerAlign:'left', headerName: 'Express', flex: 1, renderCell: (params) => {
    //         const { row } = params;
    //         if (row.serviceable.express) {
    //             return <Done color='success'/>;
    //         } else {
    //             return <Clear color='error' />;
    //         }
    //     },
    // },
    // {
    //     field: 'Surface',  hideSortIcons: true,headerAlign:'left', headerName: 'Surface', flex: 1, renderCell: (params) => {
    //         const { row } = params;
    //         if (row.serviceable.surface) {
    //             return <Done color='success'/>;
    //         } else {
    //             return <Clear color='error' />;
    //         }
    //     },
    // },

    {
        field: 'Reverse', hideSortIcons: true, headerAlign: 'left', headerName: 'Reverse', flex: 1, renderCell: (params) => {
            const { row } = params;
            if (row.serviceable.reverse) {
                return <Done color='success' />;
            } else {
                return <Clear color='error' />;
            }
        },
    },


    ];


    return (
        <>
            <div id="main-content" className="container-fluid">
                {isLoading2 && <Loader />}
                <PageTitle>Serviceability Check</PageTitle>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmitForm(onSubmit)}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={3}>
                                    <RHFTextField
                                        mandatory="true"
                                        name="pickup_pincode"
                                        label="Source Pincode"
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <RHFTextField
                                        mandatory="true"
                                        name="drop_pincode"
                                        label="Destination Pincode"
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <LoadingButton loading={isLoading} type="submit" size='medium' variant="contained" color="primary">Check Serviceability</LoadingButton>
                                </Grid>
                                <Grid item xs={3}>
                                    <LoadingButton variant="outlined" color="error" size='medium' onClick={() => setBulkS(true)}>Download Bulk Serviceability</LoadingButton>
                                </Grid>
                                <Grid item xs={12} md={12}>

                                    {result && result.length > 0 && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Divider sx={{ my: 1 }} />
                                            </Grid>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                                                <Typography>Pincodes are serviceable! {result.length} courier partner(s) are serving these pincodes.   </Typography>
                                                <CSVLink
                                                    data={result}
                                                    headers={headers}
                                                    // className="btn btn-link d-flex"
                                                    filename={"serviceable-pincodes.csv"}
                                                >
                                                    <Button size='small' variant='contained' >
                                                        Download List
                                                    </Button>
                                                </CSVLink>
                                            </Box>
                                            <ProzoDataGrid
                                                columns={columns}
                                                // disableColumnFilter={true}rowsPerPageOptions
                                                autoHeight={true}
                                                rows={result ? result : []}
                                                pagination={false}
                                                paginationMode={"client"}
                                                rowCount={result.length}
                                                getRowId={(row) => row.account_id} />
                                        </>
                                    )
                                    }
                                    {result && result.length === 0 && (<div className="col-12 mt-5">
                                        <div className="d-flex justify-content-center">

                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                                                <Typography variant='body1'>{message ? message : 'Uh ho! We are currently not serviceable at these pincodes'} </Typography>

                                            </Box>
                                            <img src="img/search-pincodes.svg" width="292" alt="placeholder-img" />
                                        </div>
                                    </div>)
                                    }
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <ConfirmDialog
                    title=''
                    open={bulkS}
                    setOpen={setBulkS}
                    onConfirm={() => handleBulkServiceabilityDownload()}>
                    Are you sure you want to download bulk serviceability?. This may take upto 60 seconds.
                </ConfirmDialog>
            </div>
        </>
    );

}
export default withLayout(Serviceability);